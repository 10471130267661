import React from 'react';
import Checkout from '../../components/Checkout/index';
import ContextProvider from '../../components/ContextProvider';
import PrivateRoute from '../../components/PrivateRoute';

const CheckoutPage = () => {
  return (
    <ContextProvider>
      <PrivateRoute component={Checkout} verifiedEmailRequired />
    </ContextProvider>
  );
};

export default CheckoutPage;
